import processHttpResponse from './processHttpResponse';
import { getLoginData, saveLoginData } from '../storage/localStorage';
import { HttpUnauthorizedResponseError } from './exceptions';
import { reauthenticate, navigateToLogout } from './auth';
import appConfig from 'core/config';

function completetMissingOptions(options) {
  return Object.assign(
    {
      contentType: 'application/json',
      stringifyContent: true,
      automaticSuccessNotification: true
    },
    options
  );
}

function apiFetch(url, method = 'GET', payload, options, isPublic = false) {
  options = completetMissingOptions(options);

  const loginData = getLoginData();
  if (!loginData && !isPublic) {
    navigateToLogout();
    return Promise.reject('User must log in');
  }

  const headers = (function () {
    const headers = new Headers();
    if (!isPublic) {
      headers.append('Authorization', `Bearer ${loginData.token}`);
    }
    if (options.contentType) {
      headers.append('Content-Type', options.contentType);
    }
    return headers;
  })();

  const body = (function () {
    if (!payload) return null;
    if (options.stringifyContent) return JSON.stringify(payload);
    return payload;
  })();

  const request = new Request(`${appConfig.baseApiUrl}${url}`, {
    method,
    headers,
    body
  });

  const processHttpResponseOptions = {
    automaticSuccessNotification: options.automaticSuccessNotification
  };

  return fetch(request)
    .then(response => processHttpResponse(response, processHttpResponseOptions))
    .catch(async e => {
      if (e instanceof HttpUnauthorizedResponseError) {
        console.log('reauthenticating with refresh token');
        const loginData = await reauthenticate();
        saveLoginData(loginData);
        console.log('retrying fetch after reauthentication');
        return apiFetch(url, method, payload, options, isPublic);
      }
      else {
        return Promise.reject(e);
      }
    });
}

export default apiFetch;
