import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { formatDateLongMonth } from "core/util/dateUtilities";
import ServiceStatements from './ServiceStatements';
import StatementActions from './StatementActions';
import ServiceAdjustments from './ServiceAdjustments';
import ServicePayment from './ServicePayment';

const Row = ({ service }) => {
    const { customerName, startDate, amount, description, availableCreditMinutes, statements, payments } = service;
    const paid = payments.length > 0 && payments.map(x => x.amount).reduce((a, b) => a + b, 0) >= amount;
    return (
        <tr>
            <td>{customerName}</td>
            <td>{formatDateLongMonth(startDate)}</td>
            <td>
                {amount}
                {paid && <p><span style={{ marginRight: 5 }} className="icon icon-wallet" />por {payments[0].collectedByMember.firstName} en {moment.utc(payments[0].collectedDate).local().format('MMM D h:mma')}</p>}
            </td>
            <td>{availableCreditMinutes}</td>
            <td>{description || 'Membresía mensual'}</td>
            <td><ServiceStatements statements={statements} /></td>
            <td>
                {!paid && <ServicePayment service={service} />}
                <ServiceAdjustments service={service} />
                <StatementActions service={service} />
            </td>
        </tr>
    );
};

Row.propTypes = {
    service: PropTypes.any.isRequired
};

export default Row;