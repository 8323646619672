import { getLoginData } from 'core/storage/localStorage';

function isAdminUser() {
    return getLoginData().plan.name === "Administrador";
}

function isCustomerManager() {
    return getLoginData().plan.name === "Mensual - Manager";
}

export {
    isAdminUser,
    isCustomerManager
};
