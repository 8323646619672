import React from 'react';

export default ({ location }) => {
    return (
        <div className="m-4">
            Página no encontrada
            <br />
            Nombre de la ruta: {location.pathname}
        </div>
    );
}
