import React from 'react';
import PropTypes from 'prop-types';
import LinkButton from 'core/components/LinkButton';
import { Menu, Item, useContextMenu } from 'react-contexify';
import { useHistory } from 'react-router-dom';

const Row = ({ member }) => {
    const { id, name, notes, plan, isActive, customerName } = member;
    const { show } = useContextMenu({ id });
    const history = useHistory();
    function handleContextMenu(event) {
        event.preventDefault();
        show(event);
    }
    return (
        <tr>
            <td>
                <div className="pull-left">
                    <LinkButton id={id} label={name} onClick={() => history.push(`/members/edit/${id}`)} />
                    <div className="pull-right ml-2" onClick={handleContextMenu}>
                        <i className="fa fa-ellipsis-v wrapped" />
                    </div>
                    <Menu id={id} theme="dark">
                        <Item onClick={() => history.push(`/unlocks/${id}`)}>Accesos</Item>
                    </Menu>
                </div>
            </td>
            <td>{plan.name}</td>
            <td>{customerName}</td>
            <td>{notes}</td>
            <td>{isActive ? 'Sí' : 'No'}</td>
        </tr>
    );
};

Row.propTypes = {
    member: PropTypes.any.isRequired
};

export default Row;
