import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import Button from 'core/components/Button';
import Service from 'entities/service';
import Statement from 'entities/statement';
import { isCurrentMonth } from "core/util/dateUtilities";

const StatementActions = ({ service }) => {
    const { id, startDate, statements } = service;
    const history = useHistory();
    const [isGenerating, setIsGenerating] = useState(false);
    const [authorizingStatementId, setAuthorizingStatementId] = useState(null);
    const [isGeneratingNC, setIsGeneratingNC] = useState(false);
    const invoiceForCreditNote = statements.find(x => x.isInvoice && x.number && statements.filter(y => y.relatedStatementNumber === x.number).length === 0);
    const reload = () => history.go(0);
    return (
        <Fragment>
            {statements.filter(x => !x.number).map((x) =>
                <div key={x.id} className="mb-1">
                    <Button
                        showSpinner={authorizingStatementId === x.id}
                        label={`Autorizar ${x.statementType}`}
                        type="button"
                        onClick={async () => {
                            setAuthorizingStatementId(x.id);
                            try {
                                await Statement.authorizeStatement(x.id);
                                reload();
                            }
                            catch { setAuthorizingStatementId(null); }
                        }}
                        className="btn-primary"
                    />
                </div>
            )}
            {statements.length % 2 === 0 && isCurrentMonth(startDate) &&
                <Button
                    showSpinner={isGenerating}
                    label="Facturar"
                    type="button"
                    onClick={async () => {
                        setIsGenerating(true);
                        try {
                            await Service.generateInvoice(id);
                            reload();
                        }
                        catch { setIsGenerating(false); }
                    }}
                    className="btn-success"
                />}
            {invoiceForCreditNote &&
                <Button
                    showSpinner={isGeneratingNC}
                    label={`Anular #${invoiceForCreditNote.number}`}
                    type="button"
                    onClick={async () => {
                        setIsGeneratingNC(true);
                        try {
                            await Statement.generateCreditNote(invoiceForCreditNote.id);
                            reload();
                        }
                        catch { setIsGeneratingNC(false); }
                    }}
                    className="btn-danger"
                />}
        </Fragment>
    );
};

StatementActions.propTypes = {
    service: PropTypes.any.isRequired
};

export default StatementActions;
