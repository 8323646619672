import apiFetch from 'core/fetch/apiFetch';
import UnlockFilter from './unlockFilter';

const _baseEndPoint = 'unlocks';

export default class Unlock {
    static async search(filter = new UnlockFilter(), page) {
        filter.setPaginationParameters(page);
        return await apiFetch(`${_baseEndPoint}?${filter.toQueryString()}`);
    }
}
