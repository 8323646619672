import ListViewRowWrapper from 'core/components/ListViewRowWrapper';
import Row from 'components/members/MemberTableRow';
import PropTypes from 'prop-types';
import React from 'react';

const Table = ({ members, loading, onBottomScroll }) => {
    const rows = members.map(x => <Row member={x} key={x.id} />);
    const columns = ['Nombre', 'Plan', 'Cliente', 'Notas', 'Vigente?'];
    return (
        <table className="table table-hover">
            <thead><tr>{columns.map((x, idx) => <th key={idx}>{x}</th>)}</tr></thead>
            <tbody>
                <ListViewRowWrapper
                    rows={rows}
                    numberOfColumns={columns.length}
                    loading={loading}
                    onBottomScroll={onBottomScroll}
                />
            </tbody>
        </table>
    );
};

Table.propTypes = {
    members: PropTypes.arrayOf(PropTypes.any).isRequired,
    loading: PropTypes.bool.isRequired,
    onBottomScroll: PropTypes.func.isRequired
};

export default Table;
