import React, { useState, useEffect, useRef } from 'react';
import { Collapse, Container, Navbar, NavbarToggler } from 'reactstrap';
import { getLoginData } from 'core/storage/localStorage';
import { NavBrand } from 'core/components/Navigation';
import TestBanner from 'core/components/TestBanner';
import MenuButtons from './MenuButtons';
import './Menu.scss';

export default function Menu() {
  const [isOpen, setIsOpen] = useState(false);
  const collapseWrapper = useRef(null);
  useEffect(() => {
    function handleClickOutside(e) {
      if (e.target !== collapseWrapper.current && !collapseWrapper.current.contains(e.target) && isOpen) {
        setIsOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return function cleanup() {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
  const loginData = getLoginData();
  if (!loginData) {
    return null;
  }
  const showTestBanner = process.env.REACT_APP_SHOW_TEST_BANNER && process.env.REACT_APP_SHOW_TEST_BANNER.toString() === 'true';
  return (
    <div>
      {showTestBanner && <TestBanner />}
      <Navbar color="dark" dark expand="lg">
        <Container>
          <NavBrand to="/dashboard" className="mr-3">
            <img
              src="/colaboro-logo.png"
              alt="Colaboro"
              className="navbar-brand-icon"
            />
          </NavBrand>
          <div ref={collapseWrapper} style={{ display: 'contents' }}>
            <NavbarToggler onClick={() => setIsOpen(!isOpen)} />
            <Collapse isOpen={isOpen} navbar>
              <MenuButtons
                collapse={() => {
                  if (isOpen) setIsOpen(false);
                }}
                userName={loginData.userData.name} />
            </Collapse>
          </div>
        </Container>
      </Navbar>
    </div>
  );
}