import PropTypes from 'prop-types';
import React from 'react';
import { FormGroup, Label } from 'reactstrap';
import { Dropdown, AsyncDropdown } from './Dropdown';

const FormDropdown = ({ label, className, mandatory, ...rest }) => (
  <FormGroup>
    {label && <Label className="form-label">{label}{mandatory && " *"}</Label>}
    <Dropdown className={`text-left ${className}`} mandatory={mandatory} {...rest} />
  </FormGroup>
);

const AsyncFormDropdown = ({ label, className, ...rest }) => (
  <FormGroup>
    <Label className="form-label">{label}</Label>
    <AsyncDropdown className={`text-left ${className}`} {...rest} />
  </FormGroup>
);

const sharedPropTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  selectedId: PropTypes.any,
  onChange: PropTypes.func.isRequired
};

FormDropdown.propTypes = {
  ...sharedPropTypes,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
      description: PropTypes.string
    })
  ).isRequired
};

AsyncFormDropdown.propTypes = {
  ...sharedPropTypes,
  fetchItems: PropTypes.func.isRequired,
  mapItemsToDropdownItems: PropTypes.func,
  initialSearchValue: PropTypes.string
};

export { FormDropdown, AsyncFormDropdown };
export default FormDropdown;
