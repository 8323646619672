import React from 'react';
import PropTypes from 'prop-types';
import { Modal as ReacstrapModal } from 'reactstrap';
import _ from 'lodash';

export default class Modal extends React.PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    show: PropTypes.bool.isRequired,
    onShowUpdated: PropTypes.func.isRequired,
    closeOnOk: PropTypes.bool.isRequired,
    okTitle: PropTypes.string,
    onOk: PropTypes.func.isRequired,
    cancelTitle: PropTypes.string,
    onCancel: PropTypes.func,
    showCancel: PropTypes.bool,
    submitting: PropTypes.bool,
    size: PropTypes.string,
    okDisabled: PropTypes.bool
  };

  static defaultProps = {
    closeOnOk: true,
    okTitle: 'Ok',
    cancelTitle: 'Cancelar',
    showCancel: false,
    onCancel: () => null,
    okDisabled: false
  };

  hide = () => this.props.onShowUpdated(false);

  render() {
    return (
      <ReacstrapModal
        isOpen={this.props.show}
        size={this.props.size}
        modalTransition={{ timeout: 100 }}
        backdropTransition={{ timeout: 200 }}
      >
        <div className="modal-header">
          <h3 className="mb-0">{this.props.title}</h3>
        </div>
        <div className="modal-body">{this.props.children}</div>
        <div className="modal-footer">
          {this.props.showCancel && (
            <button
              className="btn btn-secondary"
              onClick={_.flowRight(
                this.props.onCancel,
                this.hide
              )}
              disabled={this.props.submitting}
            >
              {this.props.cancelTitle}
            </button>
          )}
          <button
            className="btn btn-primary"
            onClick={this.props.closeOnOk ? _.flowRight(this.props.onOk, this.hide) : this.props.onOk}
            disabled={this.props.submitting || this.props.okDisabled}
          >
            {this.props.submitting ? (
              <span>
                <i className="fa fa-spin fa-spinner" /> {this.props.okTitle}
              </span>
            ) : (
                <span>{this.props.okTitle}</span>
              )}
          </button>
        </div>
      </ReacstrapModal>
    );
  }
}
