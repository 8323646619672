import 'babel-polyfill';
import 'react-app-polyfill/ie11';
import 'array-flat-polyfill';
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

if (!Object.entries) {
    Object.entries = function (obj) {
        var ownProps = Object.keys(obj),
            i = ownProps.length,
            resArray = new Array(i); // preallocate the Array
        while (i--)
            resArray[i] = [ownProps[i], obj[ownProps[i]]];

        return resArray;
    }
}

if (!Promise.prototype.finally) {
    /*eslint no-extend-native: ["error", { "exceptions": ["Promise"] }]*/
    Promise.prototype.finally = {
        finally(fn) {
            const onFinally = cb => Promise.resolve(fn()).then(cb);
            return this.then(
                result => onFinally(() => result),
                reason => onFinally(() => Promise.reject(reason))
            );
        }
    }.finally;
}