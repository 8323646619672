import PropTypes from 'prop-types';
import React from 'react';

export const wrapperWidth = {
  s: 'col-12 col-sm-8 col-md-6 mx-auto',
  m: 'col-12 col-sm-8 col-md-10 mx-auto',
  l: 'col-12 col-sm-8 col-md-12 mx-auto'
};

const CardWrapper = ({ title, subtitle, children, width, bgColor }) => (
  <div className="container main-content" >
    <div className="row">
      <div className={width}>
        <div className="card" style={{ backgroundColor: bgColor }}>
          <div className="card-body">
            <div className="card-title">
              <h4 className="form-title">{title}</h4>
              {subtitle && <h4 className="form-title">{subtitle}</h4>}
            </div>
            <hr />
            {children}
          </div>
        </div>
      </div>
    </div>
  </div>
);

CardWrapper.defaultProps = {
  width: wrapperWidth.l
};

CardWrapper.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  width: PropTypes.string
};

export default CardWrapper;
