import MemberFilter from 'entities/memberFilter';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FilterForm, FilterInputRow, FilterSearchRow } from 'core/components/TableActions';
import { AsyncDropdown } from 'core/components/Dropdown';
import Member, { MemberInput } from 'entities/member';
import UnlockFilter from 'entities/unlockFilter';

const UnlockFilterComponent = ({ filter, onChange, onFilter, initialMember }) => {
    const [selectedMember, setSelectedMember] = useState(
        initialMember && {
            id: initialMember.id,
            label: initialMember.firstName + ' ' + initialMember.lastName
        });
    const updateFilter = updated => onChange({ ...filter, ...updated });
    return (
        <FilterForm onSearch={() => onFilter()}>
            <FilterInputRow label="Miembro">
                <AsyncDropdown
                    fetchItems={async name => Member.search(new MemberFilter({ name }))}
                    mapItemsToDropdownItems={items => items.map(x => ({ id: x.id, label: x.name }))}
                    placeholder="Seleccioná un miembro..."
                    selected={selectedMember}
                    onChange={member => {
                        updateFilter({ memberId: member && member.id });
                        setSelectedMember(member);
                    }}
                    prepopulateFromStorage={true}
                    isClearable={true} />
            </FilterInputRow>
            <FilterSearchRow />
        </FilterForm>
    );
}

UnlockFilterComponent.propTypes = {
    filter: PropTypes.instanceOf(UnlockFilter),
    onChange: PropTypes.func.isRequired,
    onFilter: PropTypes.func.isRequired,
    initialMember: PropTypes.instanceOf(MemberInput)
};

export default UnlockFilterComponent;