import CardWrapper, { wrapperWidth } from 'core/components/CardWrapper';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Form } from 'reactstrap';
import FormInput from 'core/components/FormInput';
import Button from 'core/components/Button';

const ForgotPasswordForm = ({
  email,
  onSubmit,
  onEmailUpdated,
  submitting,
  showSuccessMessage
}) => {
  const handleSubmit = e => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <CardWrapper
      title={<img src="/colaboro-logo.png" alt="Colaboro" className="w-50 h-auto" />}
      width={wrapperWidth.s}
    >
      <Form onSubmit={handleSubmit}>
        <h3 className="text-center">Cambio de contraseña</h3>
        {showSuccessMessage && <h5 className="text-center">Chequear emails y seguir el enlace correspondiente</h5>}
        {!showSuccessMessage &&
          <Fragment>
            <FormInput
              id="email"
              type="email"
              label="Email"
              value={email}
              onChange={e => onEmailUpdated(e.target.value)}
              mandatory
            />
            <div className="mx-auto d-flex flex-row flex-wrap justify-content-end">
              <Button
                label="Solicitar cambio de contraseña"
                type="submit"
                className="mt-2 btn-primary"
                showSpinner={submitting}
                onClick={handleSubmit}
              />
            </div>
          </Fragment>}
      </Form>
    </CardWrapper>
  );
};

ForgotPasswordForm.propTypes = {
  email: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onEmailUpdated: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired
};

export default ForgotPasswordForm;
