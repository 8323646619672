import Filter from 'core/filtering/filter';

export default class BookingFilter extends Filter {
    constructor(filter) {
        super({ $orderby: 'StartDate desc' });
        if (filter) {
            this.onlyFuture = filter.onlyFuture;
            return this;
        }
        this.onlyFuture = true;
    }
}
