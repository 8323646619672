import Filter from 'core/filtering/filter';

export default class UnlockFilter extends Filter {
    constructor(filter) {
        super({ $orderby: 'Date desc' });
        if (filter) {
            this.memberId = filter.memberId;
            return this;
        }
        this.memberId = null;
    }
}
