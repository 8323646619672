import Filter from 'core/filtering/filter';

export default class CustomerFilter extends Filter {
    constructor(filter) {
        super();
        if (filter) {
            this.legalName = filter.legalName;
            this.onlyCurrent = filter.onlyCurrent;
            this.onlyMonthly = filter.onlyMonthly;
            return this;
        }
        this.legalName = '';
        this.onlyCurrent = true;
        this.onlyMonthly = false;
    }
}
