import { NotificationManager } from 'react-notifications';

import {
  HttpUnauthorizedResponseError,
  HttpUnexpectedResponseError
} from './exceptions';
import ValidationResult from './validationResult';

export default async function processHttpResponse(httpResponse, options) {
  switch (httpResponse.status) {
    case 200: //ok
      var contentType = httpResponse.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        return httpResponse.json().then(x => Promise.resolve(x));
      } else {
        return httpResponse.blob().then(x => Promise.resolve(x));
      }
    case 201: //create
      if (options.automaticSuccessNotification)
        return NotificationManager.success('Operación exitosa', '', 3 * 1000);
      return httpResponse.json().then(x => Promise.resolve(x));
    case 302:
      return httpResponse.json();
    case 204: //no content
      if (options.automaticSuccessNotification)
        NotificationManager.success('Operación exitosa', '', 3 * 1000);
      return Promise.resolve(null);
    case 404: //not found
      NotificationManager.warning('Recurso no encontrado', '', 3000);
      return Promise.resolve(null);
    case 400: //bad request
      return httpResponse.json().then(json => {
        if (json.message) {
          NotificationManager.error(json.message, '', 7 * 1000);
        }
        else if (json.error && json.error.message) {
          NotificationManager.error(json.error.message, '', 7 * 1000);
        } else {
          const errorsList = json.errors || json; // errors property is used when the server returns an error through the CreateErrorResponse extension method
          new ValidationResult(errorsList).errors.map(error =>
            NotificationManager.error(error.errorMessage, '', 7 * 1000)
          );
        }
        return Promise.reject('validation error');
      });
    case 401: //unauthorized
      console.log('expired token');
      throw new HttpUnauthorizedResponseError();
    case 500: //internal server error
    case 502: //bad gateway
      NotificationManager.error('An unknown error was encountered', '', 7 * 1000);
      return Promise.reject('internal server error / bad gateway');
    case 503: //service unavailable
      NotificationManager.error('Service unavailable', '', 7 * 1000);
      return Promise.reject('service unavailable');
    default:
      return Promise.reject(new HttpUnexpectedResponseError(httpResponse));
  }
}
