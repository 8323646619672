import CustomersListPage from 'pages/customers/CustomersListPage';
import CustomerEditPage from 'pages/customers/CustomerEditPage';
import CustomerAndMemberPage from 'pages/customers/CustomerAndMemberPage';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

export default () => (
    <Switch>
        <Route exact path="/customers" component={CustomersListPage} />
        <Route exact path="/customers/create-simple" component={CustomerEditPage} />
        <Route exact path="/customers/edit/:id" component={CustomerEditPage} />
        <Route exact path="/customers/create" component={CustomerAndMemberPage} />
    </Switch>
);
