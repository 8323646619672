import _ from 'lodash';

export function groupByN(array, n) {
  if (array.length === 0) return [];
  const numberOfGroups = Math.ceil(array.length / n);
  return _(_.range(numberOfGroups))
    .map(i =>
      _(array)
        .slice(n * i)
        .take(n)
        .value()
    )
    .value();
}

export function allExceptIds(array, idsToExclude, idSelector = x => x.id) {
  return array.filter(x => !idsToExclude.includes(idSelector(x)));
}

export function allExcept(array, itemsToExclude, idSelector = x => x.id) {
  const idsToExclude = itemsToExclude.map(x => idSelector(x));
  return allExceptIds(array, idsToExclude, idSelector);
}
