import 'core/util/polyfills';

import 'styles/app.scss';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-notifications/lib/notifications.css';
import 'react-toggle/style.css';
import 'react-contexify/dist/ReactContexify.css';

import App from 'App';
import React from 'react';
import ReactDOM from 'react-dom';

import setupVersionAutoReload from 'core/util/versionUtilities';
setupVersionAutoReload();

ReactDOM.render(<App />, document.getElementById('root'));