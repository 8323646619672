import React from 'react';
import PropTypes from 'prop-types';
import Modal from './Modal';

export default class RetirementConfirmationModal extends React.PureComponent {
  static propTypes = {
    entityName: PropTypes.string.isRequired,
    instanceName: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    onShowUpdated: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    actionName: PropTypes.string.isRequired
  };

  render() {
    const { entityName, instanceName, show, onShowUpdated, onOk, onCancel, actionName } = this.props;
    return (
      <Modal
        title={`${actionName} ${entityName}`}
        show={show}
        onShowUpdated={onShowUpdated}
        okTitle={actionName}
        onOk={onOk}
        onCancel={onCancel}
        showCancel={true}
      >
        <p>
          Estás seguro que querés {actionName.toLowerCase()} {entityName}{' '} <strong>{instanceName}</strong>?
        </p>
      </Modal>
    );
  }
}
