import BookingForm from 'components/bookings/BookingForm';
import Booking, { BookingInput } from 'entities/booking';
import React from 'react';
import { withRouter } from 'react-router-dom';
import makeValidable from 'core/components/Validable';
import BookingResource from 'entities/bookingResource';
import Member from 'entities/member';
import { isAdminUser } from 'authorization/roles';

const ValidableBookingForm = makeValidable(BookingForm);

class BookingEditPage extends React.Component {
    constructor(props) {
        super(props);
        this.validableRef = React.createRef();
        this.state = {
            booking: new BookingInput(),
            bookingResources: [],
            members: [],
            availableminutes: 0,
            submitting: false,
            showValidations: false,
            loading: true
        };

        this.navigateBack = this.navigateBack.bind(this);
    }

    bookingId = () => {
        return this.props.match.params.id;
    };

    async componentDidMount() {
        const state = {
            bookingResources: await BookingResource.search(),
            availableminutes: await Booking.getAvailableMinutes(),
            loading: false
        };
        const id = this.bookingId();
        if (id) {
            state.booking = await Booking.getById(id);
        }
        if (isAdminUser()) {
            state.members = await Member.search()
        }
        this.setState(state);
    }

    save = () => {
        this.setState({ showValidations: true }, async () => {
            if (this.validableRef.current.isValid()) {
                this.setState({ submitting: true });
                const id = this.bookingId();
                const savePromise = id
                    ? this.state.booking.update(id)
                    : Booking.create(this.state.booking);
                savePromise
                    .then(() => this.props.history.push('/bookings'))
                    .catch(() => this.setState({ submitting: false }));
            };
        });
    };

    delete = async () => {
        const id = this.bookingId();
        await Booking.delete(id);
        this.navigateBack();
    }

    navigateBack = () => {
        this.props.history.push('/bookings');
    };

    render() {
        const { showValidations, booking, submitting, bookingResources, availableminutes, members, loading } = this.state;

        return (
            <ValidableBookingForm
                ref={this.validableRef}
                showValidations={showValidations}
                booking={booking}
                onSubmit={this.save}
                onCancel={this.navigateBack}
                onDelete={this.delete}
                onBookingUpdated={booking => this.setState({ booking })}
                submitting={submitting}
                bookingResources={bookingResources}
                availableminutes={availableminutes}
                members={members}
                isEdit={!!this.bookingId()}
                loading={loading}
            />
        );
    }
}

export default withRouter(BookingEditPage);
