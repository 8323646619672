import apiFetch from 'core/fetch/apiFetch';
import Filter from 'core/filtering/filter';

const _baseEndPoint = 'bookingResources';

export default class BookingResource {
    static async search(filter = new Filter(), page) {
        filter.setPaginationParameters(page);
        return await apiFetch(`${_baseEndPoint}?${filter.toQueryString()}`);
    }
}
