export class ValidationFailure {
  constructor(json) {
    this.attemptedValue = json.attemptedValue;
    this.errorCode = json.errorCode;
    this.errorMessage = json.errorMessage;
    this.propertyName = json.propertyName;
  }
}

export default class ValidationResult {
  constructor(json) {
    this.errors = json.map(x => new ValidationFailure(x));
  }
}
