import React from 'react';
import PropTypes from 'prop-types';
import { ValidableFeedback } from './Validable';
import { howManyLinesIn } from 'core/util/newLineUtilities';
import './Input.scss';

const decorationType = {
  prepend: 'input-group-prepend',
  append: 'input-group-append'
};

const InputDecoration = ({ value, type, className }) => {
  if (!value) return null;
  return (
    <div className={type}>
      <span className={`input-group-text text-light ${className}`}>{value}</span>
    </div>
  );
};

export default class Input extends React.PureComponent {
  // New properties introduced by FormInput. You can use any other input property. They are passed through to it.
  static propTypes = {
    className: PropTypes.string,
    prependValue: PropTypes.string,
    appendValue: PropTypes.string,
    mandatory: PropTypes.bool,
    mandatoryReason: PropTypes.string,
    textAreaAutoRows: PropTypes.bool
  };

  static defaultProps = {
    mandatory: false
  };

  getAutoRowsProperties = () => {
    if (!(this.props.type === 'textarea' && this.props.textAreaAutoRows))
      return {};
    return {
      rows: howManyLinesIn(this.props.value) || 1
    };
  };

  render() {
    const {
      value,
      type,
      className,
      prependValue,
      appendValue,
      mandatory,
      mandatoryReason,
      textAreaAutoRows,
      ...rest
    } = this.props;

    const invalid = mandatory && !value;

    return (
      <React.Fragment>
        <div className={`input-group ${invalid && 'invalid'}`}>
          <InputDecoration type={decorationType.prepend} value={prependValue} className={className} />
          {type === 'textarea' ? (
            <textarea
              className={`form-control ${className || ''}`}
              value={'' + value}
              {...this.getAutoRowsProperties(textAreaAutoRows)}
              {...rest}
              mandatory={mandatory ? "true" : "false"}
            />
          ) : (
              <input
                type={type}
                className={`form-control ${className || ''}`}
                value={'' + value}
                {...rest}
                mandatory={mandatory ? "true" : "false"}
              />
            )}
          <InputDecoration type={decorationType.append} value={appendValue} />
        </div>
        <ValidableFeedback invalid={invalid} text={mandatoryReason} />
      </React.Fragment>
    );
  }
}
