import Filter from 'core/filtering/filter';

export default class MemberFilter extends Filter {
    constructor(filter) {
        super({ $orderby: 'CustomerName' });
        this.name = filter ? filter.name : '';
        this.customerId = filter ? filter.customerId : null;
        this.planGroup = filter ? filter.planGroup : 1;
        this.onlyCurrent = filter ? filter.onlyCurrent : true;
    }
}
