import React from 'react';
import CardWrapper, { wrapperWidth } from 'core/components/CardWrapper';
import { Button } from 'core/components';
import Actions from 'core/components/FormDefaultActions';
import FormInput from 'core/components/FormInput';
import Dropdown from 'core/components/FormDropdown';
import Member from 'entities/member';
import { CustomerAndMemberInput } from 'entities/customer';
import PropTypes from 'prop-types';
import { Form } from 'reactstrap';
import Spinner from 'core/components/Spinner';
import { useLocation, useHistory } from 'react-router-dom';

const CustomerAndMemberData = ({ customer, onUpdate, taxResponsibilityTypes, plans }) => {
    const updateCustomer = newValue =>
        onUpdate(
            new CustomerAndMemberInput({
                ...customer,
                ...newValue
            })
        );
    return (
        <div>
            <div className="row mb-3">
                <div className="col-md-12">
                    Nota: Solo para clientes recurrentes
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <FormInput
                        id="firstName"
                        label="Nombre"
                        value={customer.firstName}
                        onChange={e =>
                            updateCustomer({
                                firstName: e.target.value
                            })
                        }
                        mandatory
                    />
                </div>
                <div className="col-md-6">
                    <FormInput
                        id="lastName"
                        label="Apellido"
                        value={customer.lastName}
                        onChange={e =>
                            updateCustomer({
                                lastName: e.target.value
                            })
                        }
                        mandatory
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <Dropdown
                        placeholder="Seleccioná un plan..."
                        label="Plan de Membresía"
                        items={
                            plans
                                .filter(x => Member.getMontlyPlanIds().includes(x.id))
                                .map(x => ({ id: x.id, label: x.name }))
                        }
                        selectedId={customer.planId}
                        onChange={planId => updateCustomer({ planId })}
                        mandatory
                    />
                </div>
                <div className="col-md-6">
                    <FormInput
                        id="address"
                        label="Domicilio"
                        value={customer.address}
                        onChange={e =>
                            updateCustomer({
                                address: e.target.value
                            })
                        }
                        mandatory
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <FormInput
                        id="email"
                        label="Email"
                        value={customer.email}
                        onChange={e =>
                            updateCustomer({
                                email: e.target.value
                            })
                        }
                        mandatory
                    />
                </div>
                <div className="col-md-6">
                    <FormInput
                        id="phoneNumber"
                        label="Número de teléfono"
                        value={customer.phoneNumber}
                        onChange={e =>
                            updateCustomer({
                                phoneNumber: e.target.value
                            })
                        }
                        mandatory
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <Dropdown
                        placeholder="Seleccioná un tipo de responsable..."
                        label="Tipo de Responsable AFIP"
                        items={taxResponsibilityTypes.map(x => ({ id: x.id, label: x.description }))}
                        selectedId={customer.taxResponsibilityTypeId}
                        onChange={taxResponsibilityTypeId => updateCustomer({ taxResponsibilityTypeId })}
                        mandatory
                    />
                </div>
                <div className="col-md-6">
                    <FormInput
                        id="documentNumber"
                        label="CUIT/DNI (sin guiones ni puntos)"
                        value={customer.documentNumber || ''}
                        onChange={e =>
                            updateCustomer({
                                documentNumber: parseInt(e.target.value)
                            })
                        }
                        mandatory
                    />
                </div>
            </div>
        </div>
    );
};

const CustomerAndMemberForm = ({ customer, onSubmit, onCancel, onCustomerUpdated, submitting, taxResponsibilityTypes, plans, loading }) => {
    const location = useLocation();
    const history = useHistory();
    const handleSubmit = e => {
        e.preventDefault();
        onSubmit();
    };
    const getCreateSimpleButton = () => {
        if (location.pathname.startsWith('/customers')) {
            return (
                <Button
                    label="Crear solo cliente"
                    type="button"
                    className="btn-link"
                    onClick={() => history.push('/customers/create-simple')}
                />
            );
        }
        else if (location.pathname.startsWith('/members')) {
            return (
                <Button
                    label="Crear solo miembro"
                    type="button"
                    className="btn-link"
                    onClick={() => history.push('/members/create-simple')}
                />
            );
        }
        else {
            throw new Error('Invalid path');
        }
    }

    const title = 'Crear cliente y miembro';
    const subtitle = getCreateSimpleButton();

    return (
        <CardWrapper title={title} subtitle={subtitle} width={wrapperWidth.m}>
            <Spinner show={loading} size="fa-3x" />
            {!loading &&
                <Form onSubmit={handleSubmit}>
                    <CustomerAndMemberData
                        customer={customer}
                        onUpdate={onCustomerUpdated}
                        taxResponsibilityTypes={taxResponsibilityTypes}
                        plans={plans}
                    />
                    <Actions
                        onSubmit={handleSubmit}
                        onCancel={onCancel}
                        submitting={submitting}
                    />
                </Form>}
        </CardWrapper>
    );
};

CustomerAndMemberForm.propTypes = {
    customer: PropTypes.instanceOf(CustomerAndMemberInput).isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onCustomerUpdated: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    taxResponsibilityTypes: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired
};

export default CustomerAndMemberForm;