import PropTypes from 'prop-types';
import React from 'react';
import { FormGroup, Label } from 'reactstrap';
import DatePicker from 'core/components/DatePicker';

const FormDatePicker = ({
  id,
  label,
  className,
  selected,
  onChange,
  filterDate,
  ...rest
}) => {
  return (
    <FormGroup className={className}>
      <Label for={id} className="form-label">{label}</Label>
      <DatePicker
        selected={selected}
        onChange={onChange}
        filterDate={filterDate}
        {...rest}
      />
    </FormGroup>
  );
};

FormDatePicker.propTypes = {
  id: PropTypes.node,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  selected: PropTypes.any,
  onChange: PropTypes.func,
  filterDate: PropTypes.func
};

export default FormDatePicker;
