import apiFetch from 'core/fetch/apiFetch';
import BookingFilter from './bookingFilter';

const _baseEndPoint = 'bookings';

export class BookingInput {
    constructor(item) {
        if (item) {
            this.bookingResourceId = item.bookingResourceId;
            this.startDate = item.startDate;
            this.duration = item.duration;
            this.notes = item.notes;
            this.memberForId = item.memberForId;
            return;
        }
        this.bookingResourceId = null;
        this.startDate = null;
        this.duration = 30;
        this.notes = null;
        this.memberForId = null;
    }
    update(bookingId) {
        return apiFetch(`${_baseEndPoint}/${bookingId}`, 'PUT', this);
    }
}

export default class Booking {
    static async search(filter = new BookingFilter(), page) {
        filter.setPaginationParameters(page);
        return await apiFetch(`${_baseEndPoint}?${filter.toQueryString()}`);
    }
    static async getById(id) {
        const entity = await apiFetch(`${_baseEndPoint}/${id}`);
        return new BookingInput(entity);
    }
    static create(item) {
        return apiFetch(_baseEndPoint, 'POST', item);
    }
    static async delete(id) {
        return apiFetch(`${_baseEndPoint}/${id}`, 'DELETE');
    }
    static async getAvailableMinutes() {
        return await apiFetch(`${_baseEndPoint}/availableminutes`);
    }
}
