import PropTypes from 'prop-types';
import React from 'react';

const ListViewWrapper = ({ title, buttons, children }) => (
  <div className="container">
    <div className="flextable">
      <h3 className="flextable-item flextable-primary page-title">{title}</h3>
      <div className="flextable-item">
        {buttons}
      </div>
    </div>
    {children}
  </div>
);

ListViewWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  buttons: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ]),
  children: PropTypes.node.isRequired
};

export default ListViewWrapper;
