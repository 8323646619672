import React, { Component, Fragment } from 'react';
import { NotificationManager } from 'react-notifications';
import { withRouter } from 'react-router-dom';
import LoginForm from './LoginForm';
import CardWrapper, { wrapperWidth } from 'core/components/CardWrapper';
import { login, loginWithThirdParty } from 'core/fetch/auth';
import { saveLoginData } from 'core/storage/localStorage';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { GoogleLogin } from 'react-google-login';
import makeValidable from 'core/components/Validable';
import './LoginPage.scss';
import appConfig from 'core/config';
import Spinner from 'core/components/Spinner';

const ValidableLoginForm = makeValidable(LoginForm);

class Login extends Component {
  constructor(props) {
    super(props);
    this.validableRef = React.createRef();

    this.state = {
      credentials: {
        email: '',
        password: ''
      },
      loading: false,
      showValidations: false,
      socialLoading: false
    };
  }

  onSubmit = async () =>
    this.setState({ showValidations: true }, async () => {
      if (this.validableRef.current.isValid()) {
        try {
          this.setState({ loading: true });
          const result = await login(this.state.credentials);
          this.loginAndRedirect(result);
        } catch (e) {
          const errorMessage = (e instanceof Error || e instanceof TypeError) ? e.message : e;
          NotificationManager.error(errorMessage, '', 3 * 1000);
        } finally {
          this.setState({ loading: false });
        }
      };
    });

  onForgotPassword = () => this.props.history.push('/forgot-password');

  loginAndRedirect = (loginResult) => {
    saveLoginData(loginResult);
    NotificationManager.success(`Bienvenido ${loginResult.userData.name}`, '', 3 * 1000);
    this.props.history.push('/dashboard');
  };

  onFacebookLogin = async (response) => {
    const { accessToken } = response;
    if (accessToken) {
      try {
        this.setState({ socialLoading: true });
        const result = await loginWithThirdParty({ accessToken }, 'facebook');
        this.loginAndRedirect(result);
      } catch (e) {
        const errorMessage = (e instanceof Error || e instanceof TypeError) ? e.message : e;
        NotificationManager.error(errorMessage, '', 3 * 1000);
      }
      finally {
        this.setState({ socialLoading: false });
      }
    }
    else {
      console.log(response);
      NotificationManager.error('Error al ingresar con Facebook', '', 3 * 1000);
    }
  };

  onGoogleLogin = async (response) => {
    const { tokenId } = response;
    if (tokenId) {
      try {
        this.setState({ socialLoading: true });
        const result = await loginWithThirdParty({ tokenId }, 'google');
        this.loginAndRedirect(result);
      } catch (e) {
        const errorMessage = (e instanceof Error || e instanceof TypeError) ? e.message : e;
        NotificationManager.error(errorMessage, '', 3 * 1000);
      }
      finally {
        this.setState({ socialLoading: false });
      }
    } else {
      console.log(response);
      NotificationManager.error('Error al ingresar con Google', '', 3 * 1000);
    }
  };

  render() {
    return (
      <CardWrapper
        title={<img src="/colaboro-logo.png" alt="Colaboro" className="w-25 h-auto" style={{ margin: -50 }} />}
        width={wrapperWidth.s}
      >
        <div className="row">
          <div className="col-md-4 pb-3">
            Con tu cuenta de
          </div>
          <div className="col-md-8">
            {this.state.socialLoading && <Spinner show={true} size="fa-3x" />}
            {!this.state.socialLoading &&
              <Fragment>
                <GoogleLogin
                  clientId={appConfig.googleClientId}
                  onSuccess={this.onGoogleLogin}
                  onFailure={this.onGoogleLogin}
                  render={renderProps => (
                    <button className="custom-google-button" onClick={renderProps.onClick}>Google<i className="fa fa-google" /></button>
                  )}
                />
                <br />
                <FacebookLogin
                  isMobile={true}
                  disableMobileRedirect={true}
                  appId={appConfig.facebookAppId}
                  fields="email"
                  callback={this.onFacebookLogin}
                  render={renderProps => (
                    <button className="custom-facebook-button" onClick={renderProps.onClick}>Facebook<i className="fa fa-facebook-f" /></button>
                  )}
                />
              </Fragment>}
          </div>
        </div>
        <hr style={{ marginTop: '1.25rem' }} />
        <div className="row">
          <div className="col-md-4 pb-3">
            Con tus datos de acceso
          </div>
          <div className="col-md-8">
            <ValidableLoginForm
              ref={this.validableRef}
              showValidations={this.state.showValidations}
              loading={this.state.loading}
              credentials={this.state.credentials}
              onSubmit={this.onSubmit}
              onForgotPassword={this.onForgotPassword}
              onCredentialUpdated={newCredentials =>
                this.setState({
                  credentials: { ...newCredentials }
                })
              }
            />
          </div>
        </div>
        <hr style={{ marginTop: '1.25rem' }} />
        <p><i className="fa fa-lock" /> Sólo miembros de Colaboro Coworking.</p>
        <p>Para registrarse, enviar solicitud a <a href="mailto:contacto@colaboro.ar">contacto@colaboro.ar</a></p>
        <hr style={{ marginTop: '1.25rem' }} />
        <div style={{ textAlign: 'right', float: 'right' }}>
          <a href="https://apps.apple.com/us/app/colaboro-coworking/id1544882307" style={{ marginRight: 20, color: '#cfd2da' }} target="_blank" rel="noopener noreferrer">
            <i className="fa fa-apple fa-2x" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.grascioni2.colaborocoworking" style={{ color: '#cfd2da' }} target="_blank" rel="noopener noreferrer">
            <i className="fa fa-android fa-2x" />
          </a>
          <span style={{ float: 'left', margin: '5px 20px' }}>App móvil</span>
        </div>
      </CardWrapper>

    );
  }
}

export default withRouter(Login);