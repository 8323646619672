import BookingsListPage from 'pages/bookings/BookingsListPage';
import BookingEditPage from 'pages/bookings/BookingEditPage';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

export default () => (
    <Switch>
        <Route exact path="/bookings" component={BookingsListPage} />
        <Route exact path="/bookings/create" component={BookingEditPage} />
        <Route exact path="/bookings/edit/:id" component={BookingEditPage} />
    </Switch>
);
