import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const Button = ({
  type,
  label,
  onClick,
  className,
  iconName,
  showSpinner,
  disabled
}) => {
  const handleClick = () => {
    if (type === 'submit') return;
    onClick();
  };
  return (
    <button
      type={type}
      className={`btn ${className}`}
      onClick={handleClick}
      disabled={disabled || showSpinner}
    >
      {label}
      {showSpinner && (
        <Fragment>
          <span>
            &nbsp;
            <i className="fa fa-spin fa-spinner" />
          </span>
        </Fragment>
      )}
      {iconName && !showSpinner && (
        <Fragment>
          &nbsp;
          <span className={`icon icon-${iconName}`} />
        </Fragment>
      )}
    </button>
  );
};

Button.defaultProps = {
  type: 'button'
};

Button.propTypes = {
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string.isRequired,
  iconName: PropTypes.string,
  showSpinner: PropTypes.bool,
  disabled: PropTypes.bool
};

export default Button;
