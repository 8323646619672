import { Button } from 'core/components';
import Member from 'entities/member';
import React, { useState } from 'react';

export const BroadcastPage = () => {
    const [sending, setSending] = useState(false);
    const [message, setMessage] = useState("");
    return (
        <div className="container-fluid container-fluid-spacious">
            <div className="container pt-3">
                <div className="row card-deck">
                    <div className="card mb-4 box-shadow p-3">
                        <h2>Email masivo</h2>
                        Solamente a clientes vigentes y recurrentes
                        <br />
                        <strong>Funcionalidad en desarrollo</strong>
                        <br />
                        <div className="justify-content-start">
                            <Button
                                className="btn btn-primary"
                                type="button"
                                showSpinner={sending}
                                onClick={async () => {
                                    setSending(true);
                                    await Member.sendTestEmail();
                                    setSending(false);
                                    setMessage("Email enviado correctamente");
                                    setTimeout(() => setMessage(""), 3000);
                                }}
                                label="Enviar email de prueba"
                            />
                            {message && <div className="alert alert-success mt-3">{message}</div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}; 