import apiFetch from 'core/fetch/apiFetch';
import MemberFilter from './memberFilter';

const _baseEndPoint = 'members';

export class MemberInput {
    constructor(item) {
        if (item) {
            this.firstName = item.firstName;
            this.lastName = item.lastName;
            this.email = item.email;
            this.phoneNumber = item.phoneNumber;
            this.profileImage = item.profileImage;
            this.notes = item.notes;
            this.planId = item.planId;
            this.customerId = item.customerId;
            this.isActive = item.isActive;
            return;
        }
        this.firstName = '';
        this.lastName = '';
        this.email = '';
        this.phoneNumber = '';
        this.profileImage = null;
        this.notes = '';
        this.planId = null;
        this.customerId = null;
        this.isActive = true;
    }
    update(memberId) {
        return apiFetch(`${_baseEndPoint}/${memberId}`, 'PUT', this);
    }
}

export default class Member {
    static async search(filter = new MemberFilter(), page) {
        filter.setPaginationParameters(page);
        return await apiFetch(`${_baseEndPoint}?${filter.toQueryString()}`);
    }
    static async getPlans() {
        return await apiFetch(`${_baseEndPoint}/plans`);
    }
    static async getById(id) {
        const entity = await apiFetch(`${_baseEndPoint}/${id}`);
        return new MemberInput(entity);
    }
    static create(item) {
        return apiFetch(_baseEndPoint, 'POST', item);
    }
    static async sendTestEmail() {
        await apiFetch("test/email", 'POST');
    }
    static getTrialPlanId() {
        return 6;
    }
    static getMontlyPlanIds() {
        return [2, 3, 4];
    }
}
