import ListViewWrapper from 'core/components/ListViewWrapper';
import Table from 'components/unlocks/UnlockTable';
import FilterComponent from 'components/unlocks/UnlockFilterComponent';
import Unlock from 'entities/unlock';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import pagination from 'core/pagination';
import UnlockFilter from 'entities/unlockFilter';
import { TableActions } from 'core/components/TableActions';
import Member from 'entities/member';
import Spinner from 'core/components/Spinner';

class UnlocksListPage extends Component {
    constructor(props) {
        super(props);
        this.search = this.search.bind(this);
        this.handleBottomScroll = this.handleBottomScroll.bind(this);
        this.state = {
            items: [],
            filter: this.initializeFilter(props.match.params.id),
            loading: true,
            hasLoadedAllPages: false,
            page: 0,
            initialMember: null,
            filterLoading: true
        };
    }

    initializeFilter(memberId) {
        return new UnlockFilter({ memberId });
    }

    search() {
        this.loadPage(0);
    }

    handleBottomScroll() {
        if (!this.state.hasLoadedAllPages) {
            this.loadPage(this.state.page + 1);
        }
    }

    loadPage(page) {
        if (!this.state.loading) {
            const state = { loading: true, page };
            if (page === 0) { state.items = []; }
            this.setState(state);
        }
        Unlock.search(this.state.filter, page).then(items =>
            this.setState({
                items: page === 0 ? items : this.state.items.concat(items),
                loading: false,
                hasLoadedAllPages: items.length < pagination.pageSize
            }));
    }

    async componentDidMount() {
        const { id } = this.props.match.params;
        if (id) {
            this.setState({ initialMember: await Member.getById(id), filterLoading: false });
        }
        else {
            this.setState({ filterLoading: false });
        }
        this.search();
    }

    render() {
        return (
            <ListViewWrapper title="Accesos">
                <TableActions>
                    {this.state.filterLoading && <Spinner show={true} />}
                    {!this.state.filterLoading &&
                        <FilterComponent
                            filter={this.state.filter}
                            onChange={x => this.setState({ filter: new UnlockFilter(x) })}
                            onFilter={this.search}
                            initialMember={this.state.initialMember}
                        />}
                </TableActions>

                <Table
                    onBottomScroll={this.handleBottomScroll}
                    unlocks={this.state.items}
                    loading={this.state.loading}
                />
            </ListViewWrapper>
        );
    }
}

export default withRouter(UnlocksListPage);
