import React, { Component, Fragment } from 'react';
import { NotificationContainer } from 'react-notifications';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { PrivateRoute } from 'core/components/PrivateRoute';

import NotFound from 'core/components/NotFound';
import ForgotPassword from 'core/authentication/forgotPassword/ForgotPasswordPage';
import Login from 'core/authentication/login/LoginPage';
import Logout from 'core/authentication/login/LogoutPage';
import ResetPassword from 'core/authentication/resetPassword/ResetPasswordPage';
import Menu from 'components/Menu';
import Dashboard from 'pages/DashboardPage';
import MemberRoutes from 'pages/members';
import CustomerRoutes from 'pages/customers';
import BookingRoutes from 'pages/bookings';
import UnlockRoutes from 'pages/unlocks';
import ServicesRoutes from 'pages/services';
import ManagementRoutes from 'pages/management';

function noMenuRoute(pathname) {
  const routes = ['/login', '/forgot-password', '/reset-password'];
  return routes.includes(pathname);
}

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Fragment>
          <NotificationContainer />
          <Route path="/" render={routeProps => (noMenuRoute(routeProps.location.pathname) ? null : <Menu />)} />
          <Switch>
            <PrivateRoute exact path="/" component={Dashboard} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/reset-password" component={ResetPassword} />
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <PrivateRoute path="/members" component={MemberRoutes} />
            <PrivateRoute path="/customers" component={CustomerRoutes} />
            <PrivateRoute path="/bookings" component={BookingRoutes} />
            <PrivateRoute path="/unlocks" component={UnlockRoutes} />
            <PrivateRoute path="/services" component={ServicesRoutes} />
            <PrivateRoute path="/management" component={ManagementRoutes} />
            <PrivateRoute exact path="/logout" component={Logout} />
            <PrivateRoute component={NotFound} />
          </Switch>
        </Fragment>
      </BrowserRouter>
    );
  }
}

export default App;
