import React from 'react';
import PropTypes from 'prop-types';
import Toggle from 'react-toggle';

export default class FormToggle extends React.PureComponent {
  static propTypes = {
    label: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired
  };

  render() {
    return (
      <div className="form-group mb-0">
        <Toggle
          className="align-middle"
          checked={this.props.checked}
          onChange={e => this.props.onChange(e.target.checked)}
        />
        <span className="pl-2 align-middle">{this.props.label}</span>
      </div>
    );
  }
}
