import ServiceForm from 'components/services/ServiceForm';
import Service, { ServiceInput } from 'entities/service';
import React from 'react';
import { withRouter } from 'react-router-dom';
import makeValidable from 'core/components/Validable';
import Customer from 'entities/customer'

const ValidableServiceForm = makeValidable(ServiceForm);

class ServiceEditPage extends React.Component {
    constructor(props) {
        super(props);
        this.validableRef = React.createRef();
        this.state = {
            service: new ServiceInput(),
            customers: [],
            submitting: false,
            showValidations: false,
            loading: true
        };

        this.navigateBack = this.navigateBack.bind(this);
    }

    serviceId = () => {
        return this.props.match.params.id;
    };

    async componentDidMount() {
        const state = {
            customers: await Customer.search(),
            loading: false
        };
        const id = this.serviceId();
        if (id) {
            state.service = await Service.getById(id);
        }
        this.setState(state);
    }

    save = () => {
        this.setState({ showValidations: true }, async () => {
            if (this.validableRef.current.isValid()) {
                this.setState({ submitting: true });
                const id = this.serviceId();
                const savePromise = id
                    ? this.state.service.update(id)
                    : Service.create(this.state.service);
                savePromise
                    .then(() => this.props.history.push(`/services/${this.state.service.customerId}`))
                    .catch(() => this.setState({ submitting: false }));
            };
        });
    };

    delete = async () => {
        const id = this.serviceId();
        await Service.delete(id);
        this.navigateBack();
    }

    navigateBack = () => {
        this.props.history.push('/services');
    };

    render() {
        const { showValidations, service, submitting, serviceResources, availableminutes, customers, loading } = this.state;

        return (
            <ValidableServiceForm
                ref={this.validableRef}
                showValidations={showValidations}
                service={service}
                onSubmit={this.save}
                onCancel={this.navigateBack}
                onDelete={this.delete}
                onServiceUpdated={service => this.setState({ service })}
                submitting={submitting}
                serviceResources={serviceResources}
                availableminutes={availableminutes}
                customers={customers}
                isEdit={!!this.serviceId()}
                loading={loading}
            />
        );
    }
}

export default withRouter(ServiceEditPage);
