import apiFetch from 'core/fetch/apiFetch';

const _baseEndPoint = 'statements';

export default class Statement {
    static async getHtml(id) {
        return await apiFetch(`${_baseEndPoint}/${id}/html`);
    }
    static async getPdf(id) {
        return await apiFetch(`${_baseEndPoint}/${id}/pdf`);
    }
    static async generateCreditNote(statementId) {
        return apiFetch(`${_baseEndPoint}/${statementId}/cancel`, 'POST');
    }
    static async authorizeStatement(statementId) {
        return apiFetch(`${_baseEndPoint}/${statementId}`, 'PUT');
    }
    static getLabel(statement) {
        const { statementType, number } = statement;
        return `${statementType.substring(0, statementType.length - 2)} ${number || 'N/A'}`;
    }
}
