import React from 'react';
import PropTypes from 'prop-types';

const Spinner = ({ show, children, size }) => {
  if (!show) return null;

  return (
    <span>
      &nbsp;
      <i className={`fa fa-spin fa-spinner ${size || ''}`}>{children}</i>
    </span>
  );
};

Spinner.propTypes = {
  show: PropTypes.bool.isRequired,
  size: PropTypes.string
};

export default Spinner;
