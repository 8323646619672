import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

class BottomScrollListener extends Component {
  constructor(props) {
    super(props);

    if (props.debounce) {
      this.handleOnScroll = _.debounce(this.handleOnScroll.bind(this), props.debounce, { trailing: true });
    } else {
      this.handleOnScroll = this.handleOnScroll.bind(this);
    }
  }

  componentDidMount() {
    document.addEventListener('scroll', this.handleOnScroll);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleOnScroll);
  }

  handleOnScroll() {
    const scrollNode = document.scrollingElement || document.documentElement;
    //HACK: Substracting 0.5 pixels to account for an undetermined difference.
    if (scrollNode.scrollHeight - this.props.offset - 0.5 <= scrollNode.scrollTop + window.innerHeight) {
      this.props.onBottom();
    }
  }

  render() {
    return !this.props.children ? null : <Fragment>{this.props.children}</Fragment>;
  }
}

BottomScrollListener.defaultProps = {
  debounce: 200,
  offset: 0,
  children: null,
};

BottomScrollListener.propTypes = {
  onBottom: PropTypes.func.isRequired,
  debounce: PropTypes.number,
  offset: PropTypes.number,
  children: PropTypes.element,
};

export default BottomScrollListener;