import apiFetch from 'core/fetch/apiFetch';
import ServiceFilter from './serviceFilter';

const _baseEndPoint = 'services';

export class ServiceInput {
    constructor(item) {
        if (item) {
            this.customerId = item.customerId;
            this.amount = item.amount;
            this.description = item.description;
            this.startDate = item.startDate;
            this.endDate = item.endDate;
            return;
        }
        this.customerId = null;
        this.amount = 0;
        this.description = '';
        this.startDate = null;
        this.endDate = null;
    }
    update(serviceId) {
        return apiFetch(`${_baseEndPoint}/${serviceId}`, 'PUT', this);
    }
}

export default class Service {
    static async search(filter = new ServiceFilter(), page) {
        filter.setPaginationParameters(page);
        return await apiFetch(`${_baseEndPoint}?${filter.toQueryString()}`);
    }
    static async getById(id) {
        const entity = await apiFetch(`${_baseEndPoint}/${id}`);
        return new ServiceInput(entity);
    }
    static create(item) {
        return apiFetch(_baseEndPoint, 'POST', item);
    }
    static async adjust(serviceId, adjustments) {
        return apiFetch(`${_baseEndPoint}/${serviceId}/adjust`, 'PUT', adjustments);
    }
    static async generateInvoice(serviceId) {
        return apiFetch(`${_baseEndPoint}/${serviceId}/invoices`, 'POST');
    }
    static async collectPayment(serviceId, paymentDetails) {
        return apiFetch(`${_baseEndPoint}/${serviceId}/payments`, 'POST', paymentDetails);
    }
}
