import apiFetch from 'core/fetch/apiFetch';
import CustomerFilter from './customerFilter';

const _baseEndPoint = 'customers';

export class CustomerInput {
    constructor(item) {
        if (item) {
            this.legalName = item.legalName;
            this.address = item.address;
            this.documentNumber = item.documentNumber;
            this.taxResponsibilityTypeId = item.taxResponsibilityTypeId;
            this.contactEmail = item.contactEmail;
            this.contactName = item.contactName;
            this.contractPrice = item.contractPrice;
            this.contractCreditHours = item.contractCreditHours;
            this.isMonthlyCustomer = item.isMonthlyCustomer;
            this.isActive = item.isActive;
            return;
        }
        this.legalName = '';
        this.address = '';
        this.documentNumber = null;
        this.taxResponsibilityTypeId = null;
        this.contactEmail = '';
        this.contactName = '';
        this.contractPrice = null;
        this.contractCreditHours = null;
        this.isMonthlyCustomer = true;
        this.isActive = true;
    }
    update(customerId) {
        return apiFetch(`${_baseEndPoint}/${customerId}`, 'PUT', this);
    }
}

export class CustomerAndMemberInput {
    constructor(item) {
        if (item) {
            this.firstName = item.firstName;
            this.lastName = item.lastName;
            this.email = item.email;
            this.phoneNumber = item.phoneNumber;
            this.address = item.address;
            this.documentNumber = item.documentNumber;
            this.taxResponsibilityTypeId = item.taxResponsibilityTypeId;
            this.planId = item.planId;
            return;
        }
        this.firstName = '';
        this.lastName = '';
        this.email = '';
        this.phoneNumber = '';
        this.address = '';
        this.documentNumber = null;
        this.taxResponsibilityTypeId = null;
        this.planId = null;
    }
}

export default class Customer {
    static async search(filter = new CustomerFilter(), page) {
        filter.setPaginationParameters(page);
        return await apiFetch(`${_baseEndPoint}?${filter.toQueryString()}`);
    }
    static async getTaxResponsibilityTypes() {
        return await apiFetch(`${_baseEndPoint}/taxResponsibilityTypes`);
    }
    static async getById(id) {
        const entity = await apiFetch(`${_baseEndPoint}/${id}`);
        return new CustomerInput(entity);
    }
    static async getSummaryById(id) {
        const entity = await apiFetch(`${_baseEndPoint}/${id}`);
        return {
            id,
            label: entity.legalName
        };
    }
    static create(item) {
        return apiFetch(_baseEndPoint, 'POST', item);
    }
    static createWithMember(item) {
        return apiFetch(`${_baseEndPoint}/withMember`, 'POST', item);
    }
}
