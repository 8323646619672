import pagination from 'core/pagination';

export default class Filter {
  constructor(json) {
    if (!json) return;
    this.$top = json.$top;
    this.$skip = json.$skip;
    this.$orderby = json.$orderby;
  }

  toQueryString() {
    return Object.getOwnPropertyNames(this)
      .filter(x => this[x])
      .map(x => {
        if (Array.isArray(this[x])) {
          return this[x].map(y => `${x}=${y}`).join('&');
        }
        else {
          return `${x}=${this[x]}`;
        }
      })
      .join('&');
  }

  setPaginationParameters(page) {
    if (page !== undefined) {
      this.$top = pagination.pageSize;
      this.$skip = page * pagination.pageSize;
    }
  }

  mapDate(date) {
    return date ? date.toISOString() : null;
  }
}
