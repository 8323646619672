import React from 'react';
import PropTypes from 'prop-types';
import LinkButton from 'core/components/LinkButton';
import { Menu, Item, useContextMenu } from 'react-contexify';
import { useHistory } from 'react-router-dom';

const Row = ({ customer }) => {
    const { id, legalName, contactEmail, documentNumber, isMonthlyCustomer, isActive } = customer;
    const { show } = useContextMenu({ id });
    const history = useHistory();
    function handleContextMenu(event) {
        event.preventDefault();
        show(event);
    }
    return (
        <tr>
            <td>
                <div className="pull-left">
                    <LinkButton id={id} label={legalName} onClick={() => history.push(`/customers/edit/${id}`)} />
                    <div className="pull-right ml-2" onClick={handleContextMenu}>
                        <i className="fa fa-ellipsis-v wrapped" />
                    </div>
                    <Menu id={id} theme="dark">
                        <Item onClick={() => history.push(`/services/${id}`)}>Servicios</Item>
                    </Menu>
                </div>
            </td>
            <td>{contactEmail}</td>
            <td>{documentNumber}</td>
            <td>{isMonthlyCustomer ? 'Sí' : 'No'}</td>
            <td>{isActive ? 'Sí' : 'No'}</td>
        </tr>
    );
};

Row.propTypes = {
    customer: PropTypes.any.isRequired
};

export default Row;
