import CustomerFilter from 'entities/customerFilter';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Input } from 'reactstrap';
import { FilterForm, FilterInputRow, FilterSearchRow } from 'core/components/TableActions';
import Toggle from 'react-toggle';

export default class extends Component {
    static propTypes = {
        filter: PropTypes.instanceOf(CustomerFilter),
        onChange: PropTypes.func.isRequired,
        onFilter: PropTypes.func.isRequired
    };

    updateFilter = filter => this.props.onChange({ ...this.props.filter, ...filter });

    render() {
        return (
            <FilterForm onSearch={() => this.props.onFilter()}>
                <FilterInputRow label="Razón social">
                    <Input
                        placeholder="Buscá por razón social"
                        value={this.props.filter.legalName}
                        onChange={e => this.updateFilter({ legalName: e.target.value })}
                    />
                </FilterInputRow>
                <FilterInputRow label="Sólo clientes vigentes?">
                    <Toggle
                        className="align-middle mb-2"
                        checked={this.props.filter.onlyCurrent}
                        onChange={e => this.updateFilter({ onlyCurrent: e.target.checked })}
                    />
                </FilterInputRow>
                <FilterInputRow label="Sólo clientes mensuales?">
                    <Toggle
                        className="align-middle mb-2"
                        checked={this.props.filter.onlyMonthly}
                        onChange={e => this.updateFilter({ onlyMonthly: e.target.checked })}
                    />
                </FilterInputRow>
                <FilterSearchRow />
            </FilterForm>
        );
    }
}
