import ListViewWrapper from 'core/components/ListViewWrapper';
import FilterComponent from 'components/customers/CustomerFilterComponent';
import Table from 'components/customers/CustomerTable';
import CustomerFilter from 'entities/customerFilter';
import Customer from 'entities/customer';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { CreateButton, TableActions } from 'core/components/TableActions';
import pagination from 'core/pagination';
import { getGenericData, saveGenericData } from 'core/storage/localStorage';

const filterStorageKey = 'customers-list-filters';

class CustomersListPage extends Component {
    constructor() {
        super();
        this.search = this.search.bind(this);
        this.handleBottomScroll = this.handleBottomScroll.bind(this);
    }

    state = {
        items: [],
        filter: this.initializeFilter(),
        loading: true,
        hasLoadedAllPages: false,
        page: 0
    };

    initializeFilter() {
        const saved = getGenericData(filterStorageKey);
        return saved ? new CustomerFilter(saved) : new CustomerFilter();
    }

    search() {
        saveGenericData(filterStorageKey, this.state.filter);
        this.loadPage(0);
    }

    handleBottomScroll() {
        if (!this.state.hasLoadedAllPages) {
            this.loadPage(this.state.page + 1);
        }
    }

    loadPage(page) {
        if (!this.state.loading) {
            const state = { loading: true, page };
            if (page === 0) { state.items = []; }
            this.setState(state);
        }
        Customer.search(this.state.filter, page).then(items =>
            this.setState({
                items: page === 0 ? items : this.state.items.concat(items),
                loading: false,
                hasLoadedAllPages: items.length < pagination.pageSize
            }));
    }

    componentDidMount() {
        this.search();
    }

    render() {
        return (
            <ListViewWrapper
                title="Clientes"
                buttons={[
                    (<CreateButton
                        key="1"
                        onCreate={() => this.props.history.push("/customers/create")}
                        text="Nuevo cliente"
                    />)
                ]}
            >
                <TableActions>
                    <FilterComponent
                        filter={this.state.filter}
                        onChange={x => this.setState({ filter: new CustomerFilter(x) })}
                        onFilter={this.search}
                    />
                </TableActions>
                <Table
                    onBottomScroll={this.handleBottomScroll}
                    customers={this.state.items}
                    loading={this.state.loading}
                />
            </ListViewWrapper>
        );
    }
}

export default withRouter(CustomersListPage);
