import React from 'react';
import PropTypes from 'prop-types';
import Modal from './Modal';

export default class CancelConfirmationModal extends React.PureComponent {
  static propTypes = {
    title: PropTypes.string,
    text: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    onShowUpdated: PropTypes.func.isRequired,
    onYes: PropTypes.func.isRequired,
    onNo: PropTypes.func
  };

  render() {
    return (
      <Modal
        title={this.props.title ? this.props.title : "Cancelar Operación"}
        show={this.props.show}
        onShowUpdated={this.props.onShowUpdated}
        okTitle="Yes"
        cancelTitle="No"
        onOk={this.props.onYes}
        onCancel={this.props.onNo}
        showCancel={true}
      >
        <p>{this.props.text}</p>
      </Modal>
    );
  }
}
