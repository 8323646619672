import BookingFilter from 'entities/bookingFilter';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FilterForm, FilterInputRow, FilterSearchRow } from 'core/components/TableActions';
import Toggle from 'react-toggle';

export default class extends Component {
    static propTypes = {
        filter: PropTypes.instanceOf(BookingFilter),
        onChange: PropTypes.func.isRequired,
        onFilter: PropTypes.func.isRequired
    };

    updateFilter = filter => this.props.onChange({ ...this.props.filter, ...filter });

    render() {
        return (
            <FilterForm onSearch={() => this.props.onFilter()}>
                <FilterInputRow label="Sólo reservas futuras?">
                    <Toggle
                        className="align-middle mb-2"
                        checked={this.props.filter.onlyFuture}
                        onChange={e => this.updateFilter({ onlyFuture: e.target.checked })}
                    />
                </FilterInputRow>
                <FilterSearchRow />
            </FilterForm>
        );
    }
}
