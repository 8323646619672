import moment from 'moment';
import 'moment-timezone';

export const areDatesEqual = (date1, date2) => moment(date1).isSame(date2);
export const formatDate = date => moment(date).format('LL');
export const formatDateLongMonth = date => moment(date).format('MMMM DD, YYYY');
export const formatDateShortMonth = date => moment(date).format('MMM DD, YYYY');
export const formatDateWithTime = date => moment.utc(date).local().format('LLL');
export const isCurrentMonth = date =>
    new Date().getMonth() === new Date(date).getMonth() &&
    new Date().getYear() === new Date(date).getYear();