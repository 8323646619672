import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import './DatePicker.scss';
import { ValidableFeedback } from './Validable';
import moment from 'moment';

class CustomInput extends React.PureComponent {
  render() {
    const { placeholder, onClick, className, invalid, ...rest } = this.props;
    return (
      <Fragment>
        <div
          className={`input-group cursor-pointer ${invalid && 'invalid'} ${className}`}
          onClick={onClick}
        >
          <input
            type="text"
            className="form-control"
            {...rest}
            placeholder={placeholder || "Elije una fecha"}
          />
          <div className="input-group-append input-group-text text-light">
            <i className="fa fa-calendar fa-fw" />
          </div>
        </div>
        <ValidableFeedback invalid={invalid} />
      </Fragment>
    );
  }
}

const CustomDatePicker = props => (
  <DatePicker {...props} customInput={<CustomInput invalid={!props.selected && props.mandatory ? true : false} />} />
);

class CustomDateRangePicker extends React.PureComponent {
  static propTypes = {
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
    onStartDateChange: PropTypes.func.isRequired,
    onEndDateChange: PropTypes.func.isRequired,
    mandatory: PropTypes.bool
  };

  static defaultProps = {
    mandatory: false
  };

  render() {
    const {
      startDate,
      endDate,
      onStartDateChange,
      onEndDateChange,
      mandatory
    } = this.props;
    return (
      <div className="row">
        <div className="col-md-6">
          <CustomDatePicker
            startDate={startDate}
            endDate={endDate}
            selected={startDate}
            selectsStart
            onChange={onStartDateChange}
            placeholderText="Start Date"
            mandatory={mandatory}
          />
        </div>
        <div className="col-md-6">
          <CustomDatePicker
            startDate={startDate}
            endDate={endDate}
            selected={endDate}
            selectsEnd
            onChange={endDate => {
              const correctEndDate = endDate ? moment(endDate).endOf('day').toDate() : null;
              onEndDateChange(correctEndDate);
            }}
            placeholderText="End Date"
            mandatory={mandatory}
          />
        </div>
      </div>
    );
  }
}

export { CustomDatePicker, CustomDateRangePicker };
export default CustomDatePicker;
